import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import useSesame from '@oberoninternal/travelbase-ds/hooks/useSesame';
import { Box } from '@rebass/grid';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FilterCategory } from '../entities/Filter';
import CheckboxFilter from './CheckboxFilter';
import Dots from './svg/Dots.svg';
import Divider from './Divider';
import { getAssignmentColor } from '@oberoninternal/travelbase-ds/constants/theme';
import RadioFilter from './RadioFilter';

export interface FilterListCategoryProps {
    category: FilterCategory;
    type?: 'checkbox' | 'radio';
}

const FILTER_SIZE = 4;

const FilterListCategory = ({ category, type = 'checkbox' }: FilterListCategoryProps) => {
    const { open, onOpen } = useSesame();
    if (!category.filters?.length) {
        return null;
    }
    const FilterComponent = type === 'checkbox' ? CheckboxFilter : RadioFilter;

    return (
        <FilterContainer>
            <Box>
                <FilterCaption variant="micro">{category.label}</FilterCaption>
                {category.filters.slice(0, open ? undefined : FILTER_SIZE).map(filter => (
                    <FilterComponent
                        key={filter.handle}
                        label={filter.label}
                        fieldValue={filter.handle}
                        fieldName={category.formikKey ?? category.handle}
                        id={filter.handle}
                        hits={filter.hits}
                        categoryHandle={category.handle}
                    />
                ))}
                {!open && category.filters.length > FILTER_SIZE && (
                    <StyledTextButton onClick={onOpen} size="tiny">
                        <DotsIcon />
                        <FormattedMessage
                            defaultMessage="Alle {numberOfFilters} tonen"
                            values={{ numberOfFilters: category.filters.length }}
                        />
                    </StyledTextButton>
                )}
            </Box>
            <Divider />
        </FilterContainer>
    );
};

export default FilterListCategory;

const DotsIcon = styled(Dots)`
    color: inherit;
`;

const FilterContainer = styled.div`
    display: flex;
    flex-flow: column;

    > span + span {
        margin-top: 1rem;
    }
`;

const FilterCaption = styled(Body)`
    font-weight: bold;
`;

const StyledTextButton = styled(TextButton)`
    color: ${({ theme }) => theme.colors.neutral['90']};
    span {
        font-weight: 600;

        svg {
            margin-right: 1.2rem;
        }
    }

    &:hover {
        color: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.textAction)};
        span:after {
            border: none;
        }
    }

    span {
        font-weight: 600;

        svg {
            margin-right: 1.2rem;
        }
    }
`;
