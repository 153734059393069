import React, { FC, ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import Cross from '@oberoninternal/travelbase-ds/components/figure/Cross';
import { getAssignmentColor } from '@oberoninternal/travelbase-ds/constants/theme';

const TagButton: FC<React.PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>>> = ({ children, ...props }) => (
    <StyledButton {...props}>
        <Cross width={10} height={10} />
        {children}
    </StyledButton>
);

export default TagButton;

const StyledButton = styled(Button).attrs({ rounded: true, size: 'small' })`
    && {
        > span > svg {
            margin-right: 0.8rem;
        }
        padding: 0 1.6rem;

        transition: box-shadow 0.2s ease-in-out;

        background: ${({ theme }) =>
            theme.colorAssignments.tagButton
                ? getAssignmentColor(theme, theme.colorAssignments.tagButton.backgroundColor)
                : theme.colors.secondary['5']};

        color: ${({ theme }) =>
            theme.colorAssignments.tagButton
                ? getAssignmentColor(theme, theme.colorAssignments.tagButton.color)
                : theme.colors.secondary['80']};

        --border-color: transparent;

        :focus,
        :active {
            --border-color: ${({ theme }) =>
                theme.colorAssignments.tagButton
                    ? getAssignmentColor(theme, theme.colorAssignments.tagButton.borderColor)
                    : theme.colors.secondary['80']};
        }

        :hover {
            --border-color: ${({ theme }) =>
                theme.colorAssignments.tagButton
                    ? getAssignmentColor(theme, theme.colorAssignments.tagButton.borderColor)
                    : theme.colors.secondary['70']};
        }
    }
`;
