import pick from 'lodash.pick';
import { Filter } from '../../entities/Filter';

type ResolvedFilter = { label: string; handle: string };

type FilterPropertyShape = Record<string, unknown>;

interface Options<FilterProperty extends FilterPropertyShape, FilterCount extends FilterPropertyShape> {
    filterProperties: FilterProperty[];
    filterCounts: FilterCount[];
    categoryHandle: string;
    categoryLabel?: string;
    /**
     * The filter component expects for each filter to have a `label` and `handle` prop. This callback can be used in order to resolve these props if the `filterProperties` array has a different shape.
     */
    filterResolver?: (entry: FilterProperty) => ResolvedFilter;
    /**
     * Normally the `categoryHandle` param will also be its formik key. However sometimes you'd want it to be * something else. For example in the accommodation search we use 'and' and 'or' filters which are dynamically composed.
     *
     * */
    formikKey?: string;
    filterCountKey: Exclude<keyof FilterCount, 'hits' | '__typename'>;
}

/**
 * Convenience util to compose a filter category from certain backend result set. It looks at the filter counts in order to determine what filters to show.
 */
const getFilterHits = <T extends FilterPropertyShape & { hits?: number }>(
    property: string,
    propertyFilterCounts: Array<T>,
    entryKey: keyof T
) => propertyFilterCounts?.find(propertyFilterCount => propertyFilterCount[entryKey] === property)?.hits;

const composeFilterCategory = <FilterProperty extends FilterPropertyShape, FilterCount extends FilterPropertyShape>(
    opts: Options<FilterProperty, FilterCount>
) => {
    const { formikKey, categoryHandle, categoryLabel, filterProperties, filterResolver, filterCountKey, filterCounts } =
        opts;
    return {
        formikKey,
        handle: categoryHandle,
        label: categoryLabel,
        filters: filterProperties?.reduce<Filter[]>((acc, next) => {
            const resolvedFilter = filterResolver?.(next) ?? (pick(next, ['handle', 'label']) as ResolvedFilter);
            const filterHits = getFilterHits(resolvedFilter.handle, filterCounts, filterCountKey);

            if (!filterHits) {
                return acc;
            }
            return [
                ...acc,
                {
                    hits: filterHits,
                    ...resolvedFilter,
                },
            ];
        }, []),
    };
};

export default composeFilterCategory;
