import Checkbox from '@oberoninternal/travelbase-ds/components/form/Checkbox';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import { Box, Flex } from '@rebass/grid';
import { useField } from 'formik';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

export interface PropertyHitProps {
    id: string;
    /**
     * name of the checkbox group
     */
    fieldName: string;
    label: ReactNode;
    hits?: number;
    /**
     * value of the specific checkbox in the group
     */
    fieldValue: string;
}

export const scrollTopOnChange = () => {
    const pageEl = document.getElementById('search-page');
    if (pageEl) {
        const rect = pageEl.getBoundingClientRect();
        if (rect.top < 0) {
            window.scrollTo(0, pageEl.offsetTop - 32);
        }
    }
};

const CheckboxFilter = ({ hits, fieldName, id, label, fieldValue }: PropertyHitProps) => {
    const [{ onChange, ...props }] = useField({ name: fieldName, value: fieldValue, type: 'checkbox' });
    return (
        <Flex my={4}>
            <Checkbox
                id={id}
                onChange={e => {
                    onChange(e);
                    scrollTopOnChange();
                }}
                {...props}
            />
            <Label htmlFor={id}>
                <Box>
                    {label}
                    {hits && <HitCount>{` (${hits})`}</HitCount>}
                </Box>
            </Label>
        </Flex>
    );
};

export default CheckboxFilter;

const HitCount = styled.span`
    color: ${({ theme }) => theme.colors.neutral['50']};
`;
