import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import StyledButtonGroup from '@oberoninternal/travelbase-ds/components/action/ButtonGroup';
import React from 'react';
import styled from 'styled-components';
import Map from '@oberoninternal/travelbase-ds/svg/Map.svg';
import ListBullets from '../svg/ListBullets.svg';
import { FormattedMessage } from 'react-intl';

interface Props {
    allowMap: boolean;
    allowList: boolean;
    onMap?: () => void;
    onList?: () => void;
}

const ViewToggle: React.FC<React.PropsWithChildren<Props>> = ({ allowMap, allowList, onMap, onList, ...props }) => (
    <StyledViewToggle {...props}>
        <Button variant={!allowMap ? 'secondary' : 'outline'} onClick={onMap}>
            <Map width={16} height={16} />
            <span>
                <FormattedMessage defaultMessage="Kaart" />
            </span>
        </Button>
        <Button variant={!allowList ? 'secondary' : 'outline'} onClick={onList}>
            <ListBullets width={16} height={16} />
            <span>
                <FormattedMessage defaultMessage="Lijst" />
            </span>
        </Button>
    </StyledViewToggle>
);

export const StyledViewToggle = styled(StyledButtonGroup)`
    display: none;
    --buttonContainerRadius: calc(${({ theme }) => theme.radius.button} + ${({ theme }) => theme.radius.button}*0.5);

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        display: flex;

        > button:first-of-type {
            border-top-left-radius: var(--buttonContainerRadius) !important;
            border-bottom-left-radius: var(--buttonContainerRadius) !important;
        }
        > button:last-of-type {
            border-top-right-radius: var(--buttonContainerRadius) !important;
            border-bottom-right-radius: var(--buttonContainerRadius) !important;
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
        > button {
            padding: 0 2.4rem;
        }
    }
`;

export default ViewToggle;
