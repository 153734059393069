import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { Flex, Box } from '@rebass/grid';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FilterCategory } from '../entities/Filter';
import SearchFilterTag from './SearchFilterTag';
import Divider from './Divider';

interface Props {
    categories: FilterCategory[];
    shouldAutoSave?: boolean;
}

const SearchFilterTags: FC<React.PropsWithChildren<Props>> = ({ shouldAutoSave, categories }) => {
    const { submitForm, values, setFieldValue } = useFormikContext<Record<string, undefined | string[]>>();

    const createOnRemoveHandler = (name: string, activeHandle: string) => () => {
        setFieldValue(
            name,
            values[name]?.filter(handle => handle !== activeHandle)
        );

        if (!shouldAutoSave) {
            submitForm();
        }
    };

    const activeFilters = categories
        .flatMap(
            category =>
                category.filters?.map(filter => ({ ...filter, category: category.formikKey ?? category.handle })) ?? []
        )
        .filter(filter => values[filter.category]?.includes(filter.handle));
    if (!activeFilters?.length) {
        return null;
    }

    return (
        <Box mb={4}>
            <Body className="gt-s" variant="micro" style={{ fontWeight: 500, marginBottom: '1.6rem' }}>
                <FormattedMessage defaultMessage="Geselecteerde filters" />
            </Body>
            <Container>
                {activeFilters.map(({ category: group, handle, label }) => {
                    const onClickHandler = createOnRemoveHandler(group, handle);
                    return (
                        <SearchFilterTag key={handle} onClick={onClickHandler}>
                            {label}
                        </SearchFilterTag>
                    );
                })}
            </Container>
            <Divider />
        </Box>
    );
};

export default SearchFilterTags;

const Container = styled(Flex).attrs({ width: 1 })`
    > * {
        flex-shrink: 0;
    }
    margin-bottom: ${({ theme }) => theme.spacing['40_Standard']};

    @media screen and (max-width: ${({ theme }) => theme.mediaQueriesValues.s - 1}px) {
        overflow-x: auto;
        width: calc(100% + var(--wrapperPadding) * 2);
        padding-bottom: 0.8rem;
    }
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        flex-wrap: wrap;
    }
`;
