import { useCallback, useMemo } from 'react';
import { DecodedValueMap } from 'use-query-params';
import useQueryParams from './useQueryParams';

const useFiltersActive = (filterParamsConfig: DecodedValueMap<{}>) => {
    const [params, setParams] = useQueryParams(filterParamsConfig);
    const filtersActive = useMemo(
        () =>
            Object.entries(params).some(([, value]) => {
                if (Array.isArray(value)) {
                    return value.length > 0;
                }
                return !!value;
            }),
        [params]
    );

    const removeActiveFilters = useCallback(
        () =>
            setParams(
                Object.keys(filterParamsConfig).reduce((newParams, key) => ({ ...newParams, [key]: undefined }), {})
            ),
        [filterParamsConfig, setParams]
    );
    return [filtersActive, removeActiveFilters] as const;
};

export default useFiltersActive;
