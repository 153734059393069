import { Box } from '@rebass/grid';
import React, { FC } from 'react';
import TagButton from './TagButton';

interface Props {
    onClick: () => void;
}
const SearchFilterTag: FC<React.PropsWithChildren<Props>> = props => (
    <Box pr={3} mt={[4, null, 0]} mb={[null, null, 3]}>
        <TagButton type="button" {...props} />
    </Box>
);
export default SearchFilterTag;
