import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import { Box, Flex } from '@rebass/grid';
import { useField } from 'formik';
import React from 'react';
import styled from 'styled-components';
import Radio from '@oberoninternal/travelbase-ds/components/form/Radio';
import { PropertyHitProps, scrollTopOnChange } from './CheckboxFilter';

const RadioFilter = ({
    hits,
    fieldName,
    id,
    label,
    fieldValue,
    categoryHandle,
}: PropertyHitProps & { categoryHandle: string }) => {
    const [, { value }, { setValue }] = useField<string[]>({ name: fieldName, value: fieldValue, type: 'radio' });
    return (
        <Flex my={4}>
            <Radio
                id={id}
                value={fieldValue}
                checked={value.includes(fieldValue)}
                onChange={e => {
                    setValue([...value.filter(val => !val.startsWith(categoryHandle)), e.currentTarget.value]);
                    scrollTopOnChange();
                }}
            />
            <Label htmlFor={id}>
                <Box>
                    {label}
                    {hits && <HitCount>{` (${hits})`}</HitCount>}
                </Box>
            </Label>
        </Flex>
    );
};

export default RadioFilter;

const HitCount = styled.span`
    color: ${({ theme }) => theme.colors.neutral['50']};
`;
